.sider {
    overflow: auto;
    height: 100vh;
    position: fixed !important;
    inset-inline-start: 0;
    top: 0;
    bottom: 0;
    scrollbar-width: thin;
    scrollbar-gutter: 'stable';
}
