$primary-color: #1677ff;

.text-sm {
  font-size: 12px;
}

.text-hover-primary:hover {
  color: $primary-color;
}

.left-0 {
  left: 0
}

.top-0 {
  top: 0
}

.artwork-config-modal {
  .ant-upload.ant-upload-select {
    width: 100% !important;
    height: 160px !important;
  }
}

.avatar-uploader {
  margin-top: 12px;
}
